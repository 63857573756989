<template>
  <WsMain>
    <WsCrud
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :liveSearching="true"
      :showFields="showFields"
      :infiniteScroll="true"
      :expandable="false"
      :showExpand="false"
      :createHideFields="createHideFields"
      :pageMode="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
import tulpa_section from "@/__vue2stone_cms/models/tulpa_section";
export default {
  metaInfo() {
    return {
      title: "頁面用區塊",
    };
  },

  data: () => ({
    modelName: "tulpa_section",
    label: tulpa_section.label,
    fields: tulpa_section.fields,
    showFields: ["id", "name", "component_name", "tags", "remark"],
    createHideFields: [],
  }),
};
</script>